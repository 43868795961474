




























































































































import axios from '@/utils/axios';
import { langGeneral, langUI } from '@/utils/constants';
import { EventBus } from '@/utils/eventbus';
import { ILang, IPosition } from '@/utils/interfaces';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class LanguageDetails extends Vue {
  public segment = 1;
  public selectedPosition: null | IPosition = null;
  public newTitle = '';
  public newDescription = '';
  public uiElement = langUI;
  public generalElement = langGeneral;
  public compID = '1704';

  async mounted() {
    EventBus.$on('sk-select-position-return', (id: string) => {
      this.selectedPosition = this.$store.getters.position(id);
    });

    const { data } = await axios.get('lang/' + this.langId);
    if (data && data.data) {
      this.$store.commit('langLoadedNAS', data);
      this.compID = '1705';
    }
  }

  get langId(): string {
    return this.$route.params.id;
  }

  public reset(): void {
    this.newTitle = '';
    this.newDescription = '';
  }

  public openSelectPosition(): void {
    EventBus.$emit('sk-select-position');
  }

  public async updatePosition(): Promise<void> {
    const langId = this.langId;
    if (this.newTitle.length > 0) {
      axios.put('lang/update/' + langId, {
        key: 'position.' + this.selectedPosition?._id + '.title',
        value: this.newTitle
      });
    }
    if (this.newDescription.length > 0) {
      axios.put('lang/update/' + langId, {
        key: 'position.' + this.selectedPosition?._id + '.description',
        value: this.newDescription
      });
    }
  }

  public async updateUI(tab: string): Promise<void> {
    this.update(tab, this.uiElement);
  }

  public async updateGeneral(tab: string): Promise<void> {
    this.update(tab, this.generalElement);
  }

  public async update(
    tab: string,
    entries: Record<string, Record<string, { key: string; value: string }>>
  ): Promise<void> {
    let inputs: { key: string; value: string }[] = [];
    for (const [key, value] of Object.entries(entries)) {
      if (key === tab) {
        inputs = Object.values(value);
      }
    }
    const langId = this.langId;
    inputs
      .filter(x => x.value.length !== 0)
      .forEach(x => {
        axios.put('lang/update/' + langId, x);
        x.value = '';
      });
  }

  get languages(): ILang[] {
    return this.$store.getters.langs;
  }

  get language(): ILang {
    const res = this.languages.filter(x => x._id === this.langId);
    if (res && res.length > 0) return res[0];
    this.$router.push({ name: 'languages' });
    return this.languages[0];
  }
}
